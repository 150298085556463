.nav-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    padding: 20px 0px;
    background-color: #222222;
    transition: transform 0.7s ease;
    position: fixed;
    z-index: 10;
    box-shadow: 0px 0px 5px 0px #000000;
}

.nav-bar.hidden {
    transform: translateY(-100%);
}

.nav-bar a {
    color: white;
    font-size: 1.3rem;
    font-weight: lighter;
}

.nav-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    max-width: 1000px;
    width: 100%;
    padding: 0px 15px;
    margin: auto;
}

.small-logo {
    height: 40px;
    width: auto;
    cursor: pointer;
}

.nav-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
}

.nav-button {
    background: none;
    color: white;
    border: none;
    font-weight: 500;
    border: 2px solid white;
    width: 145px;
}

.nav-button.active {
    background-color: white;
    color: #222222;
    font-weight: bold;
}

.nav-button:hover {
    background-color: white;
    color: #222222;
    font-weight: bold;
}

.nav-name {
    font-size: 1.6rem;
    color: white;
    text-align: center;
    width: 250px;
    font-weight: 500;
}

.hamburger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    background-color: #222222;
    border: none;
    /* margin-bottom: 0px; */
}

.hamburger-menu:hover {
    background-color: #222222;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 90;
}

.nav-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s all;
    transform: translateX(100%);
    padding: 25px;
    background-color: #222222;
    z-index: 100;
}

.nav-open {
    width: 260px;
    transform: translateX(0);
    box-shadow: 0 0 15px 0 #000;
}

.nav-close {
    transform: translateX(100%);
}

.x-button {
    position: absolute;
    background-color: #c6133e;
    border-color: white;
    padding: 0px;
    height: 40px;
    width: 40px;
    top: 20px;
    right: 20px;
    font-size: 1.0rem;
    color: white;
}

.x-button:hover {
    background-color: #a61033;
}

.panel-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 600px;
    align-items: center;
}

.panel-button {
    font-size: 1.4rem;
}

.panel-logo {
    cursor: default !important;
    margin: 0px !important;
    /* margin-top: 20px !important; */
}

.panel-language-button {
    width: 145px;
    border-color: white;
    background-color: #222222;
    color: white;
    font-weight: lighter;
    margin-bottom: 0px !important;
}

.panel-language-button:hover {
    /* border: 2px solid white; */
    font-weight: bold;
    background-color: white;
    color: #222222;
}

@media screen and (max-width: 480px) {
    .nav-bar {
        padding: 15px 0px;
    }

    .panel-language-button,
    .nav-button {
        /* width: 110px; */
        font-size: 1.1rem;
    }

    .nav-name {
        font-size: 1.3rem;
    }

    .nav-logo-container {
        width: 110px;
    }
}