body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  background-color: #f6f1eb;
  font-size: 17px;
}

ul {
  list-style-type: none;
  padding: 0px;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

button {
  border: 2px solid black;
  color: white;
  background-color: rgb(255, 250, 250);
  color: #222222;
  padding: 5px 10px;
  font-family: 'Playfair Display', serif;
  font-size: 1.3rem;
  font-weight: bold;
  transition: 0.7s all;
}

button:hover {
  cursor: pointer;
  background-color: #222222;
  color: white;
}

.playfair {
  font-family: 'Playfair Display', serif;
}

.urbanist {
  font-family: 'Urbanist', sans-serif;
}