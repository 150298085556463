.gallery-section {
    margin: auto;
}

.home-subheading {
    margin-top: 0px;
}

.gallery-inner-container {
    display: flex;
    flex-direction: row;
}

.slider-container .slick-slide img {
    border: 2px solid black;
}

.gallery-image {
    height: 350px;
}

.slick-arrow {}

.slick-next {
    right: 15px !important;
}

.slick-prev {
    left: 15px !important;
}

.slick-next,
.slick-prev {
    /* z-index: 1; */
    top: 170px;
}

.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
    color: black;
    /* z-index: 1; */
}

.slick-list {
    padding: 0 0% !important;
    /* max-width: 1000px; */
}

.slider-container {
    /* display: flex; */
    margin: auto;
    max-width: 1500px;
}

/* .slick-track {
    max-width: 1000px;
} */

.slick-slide {
    display: flex !important;
    justify-content: center !important;
    cursor: grab;
}

.slick-slider {
    display: flex;
    justify-content: center;
}

/* button::before {
    margin-top: 7px !important;
} */

/* .slick-dots {
    width: 250px !important;
} */

.slick-slider .slick-dots {
    max-width: 330px;
}

.slick-dots button:before {
    margin-top: 10px;
}

@media screen and (min-width: 1200px) {
    .slick-next {
        top: 381px !important;
        right: 40% !important;
    }

    .slick-prev {
        top: 381px !important;
        left: 40% !important;
    }
}

@media screen and (min-width: 775px) {
    .slick-dots {
        bottom: -7.5% !important;
    }
}

@media screen and (min-width: 770px) {
    .slick-next {
        top: 384px !important;
        right: 33% !important;
    }

    .slick-prev {
        top: 384px !important;
        left: 33% !important;
    }

    .slick-dots {
        bottom: -12.5%;
        width: 220px !important;
    }
}

@media screen and (max-width: 770px) {
    .slick-dots {
        bottom: -12%;
        max-width: 230px !important;
    }

    .slick-next {
        top: 425px !important;
        right: 42% !important;
    }

    .slick-prev {
        top: 425px !important;
        left: 42% !important;
    }

    .slick-prev:before,
    .slick-next:before {
        /* opacity: 0 !important; */
    }
}

@media screen and (max-width: 420px) {
    .slick-dots {
        bottom: -14%;
    }

    .slick-next {
        top: 375px !important;
        right: 42% !important;
    }

    .slick-prev {
        top: 375px !important;
        left: 42% !important;
    }

    .gallery-image {
        height: 300px;
    }
}