.footer-container {
    width: 100%;
    background-color: #222222;
}

.footer-section {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    color: white;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
}

.footer-options-container {
    margin-right: 10px;
}

.footer-section a {
    color: white;
}

.footer-section li {}

.footer-heading {
    font-size: 1.7rem;
    margin-bottom: 5px;
    text-align: center;
}

.first-footer-section {
    display: flex;
    flex-direction: row;
}

.big-logo {
    height: 140px;
    width: auto;
    margin-right: 30px;
    margin-top: 20px;
    cursor: pointer;
}

.footer-heading {}

.footer-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: Urbanist, sans-serif;
}

.footer-icon {
    height: 20px;
    width: 20px;
}

.footer-icon-container {
    display: flex;
    justify-content: center;
    text-align: center;
    /* align-items: center; */
    margin-bottom: 8px;
}

.footer-icon-description {
    display: flex;
    margin: 0px 5px;
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
    /* align-items: start; */
}

.GB-map-icon {
    /* margin-right: -12px; */
    /* margin-left: 12px; */
    /* height: 100%; */
    margin-right: 4px;
}

.AN-map-icon {
    /* margin-right: 5px; */
}

.credits-container {
    display: flex;
    justify-content: center;
    color: white;
    padding-bottom: 20px;
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    align-items: center;
}

.credits-created-by {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 1.3rem;
}

.isaiah {
    color: white;
    margin-left: 5px;
    font-size: 1.3rem;
}

.isaiah:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1050px) {
    .footer-section {
        flex-direction: column;
        align-items: center;
    }

    .footer-icon-container {
        /* align-items: normal; */
    }

    .footer-heading {
        display: flex;
        justify-content: center;
    }

    .footer-options-container {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .footer-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .big-logo {
        margin: 0px;
        margin-top: 10px;
    }

    .credits-container {
        flex-direction: column;
    }
}

@media screen and (max-width: 415px) {
    .footer-icon-container {
        /* align-items: normal; */
    }

    /* .GB-map-icon,
    .AN-map-icon {
        margin-right: 5px;
    } */
}