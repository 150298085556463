.fade-in {
    animation: heroAnim 0.75s ease-out 0s forwards;
    animation-delay: 0s;
}

/* transform: translateY(5%); */

@keyframes heroAnim {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fade1,
.fade2,
.fade3 {
    opacity: 0;
}

.fade1 {
    animation: hero1Anim 0.75s ease-out 0s forwards;
    animation-delay: 0.1s;
}

/* transform: translateY(5%); */

@keyframes hero1Anim {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fade2 {
    animation: hero2Anim 0.75s ease-out 0s forwards;
    animation-delay: 0.6s;
}

/* transform: translateY(5%); */

@keyframes hero2Anim {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.fade3 {
    animation: hero3Anim 0.75s ease-out 0s forwards;
    animation-delay: 1.1s;
}

/* transform: translateY(5%); */

@keyframes hero3Anim {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.header-container {}

.hero-section {
    padding-top: 130px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    margin: auto;
    /* margin-top: 70px; */
}

.hero-information {
    /* margin-top: 90px; */
    margin-right: 10px;
    text-align: center;
}

.title {
    font-size: 2.7rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

.location {
    font-size: 2.1rem;
    margin-top: 0px;
    margin-bottom: 10px;
}

.language-toggle-button {
    margin-bottom: 10px;
}

.description {
    font-weight: 500;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.hero-descriptions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.description-2 {}

.description-hours {
    text-align: center;
    margin-bottom: 0px;
}

.description-hours-bottom {
    margin-bottom: 10px;
}

.hero-buttons-container {
    max-width: 510px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.hero-image-and-contact {
    position: relative;
}

.view-gallery-hero,
.view-services-hero,
.view-reviews-hero,
.leave-review-hero {
    width: 200px;
}

.view-services-hero,
.view-reviews-hero,
.leave-review-hero {
    margin-top: 5px;
}

.hero-image-container {
    border: 2px solid #222222;
    position: relative;
    height: 500px;
    width: auto;
    overflow: hidden;
}

.hero-image {
    height: 100%;
    object-fit: cover;
}

/* .hero-image:hover {
    transform: scale(1.05);
} */

.contact-container {
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 0px;
}

/*
.map-span {
    margin-right: 5px;
    margin-top: 5px;
} */

.contact-heading-container {
    display: flex;
    justify-content: center;
}

.contact-heading-text {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.8rem;
    font-weight: lighter;
}

.contact-container a {
    color: white;
}

.gradient-overlay {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1.0) 20%, rgba(0, 0, 0, 0));
}

@media screen and (min-width: 1000px) {
    .title {
        font-size: 2.9rem;
    }

    .location {
        font-size: 2.3rem;
    }

    .description {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1000px) {

    .title,
    .location,
    .description {
        text-align: center;
    }

    .hero-section {
        flex-direction: column;
    }

    .hero-information {
        margin-right: 0px;
    }

    .hero-buttons-container {
        margin-bottom: 20px;
    }

    .hero-image {
        height: auto;
        width: 310px;
    }

    .hero-image-and-contact {
        margin: auto;
    }

    .gradient-overlay {
        width: 313px;
    }
}

@media screen and (max-width: 480px) {
    .hero-section {
        padding-top: 100px;
    }
}