#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 100; */
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* z-index: -100; */
    /* overflow: hidden !important; */
}

#modal-content {
    position: absolute;
    margin: 10px;
    background-color: white;
    border: 2px solid black;
    overflow: auto;
}

.modal-button {
    background: none !important;
    border: none;
    box-shadow: 0px 0px 0px 0px;
    padding: 0px;
}

.no-scroll {
    overflow: hidden;
}