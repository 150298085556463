.image-modal {
    display: flex;
    justify-content: center;
    margin: auto;
}

.image-modal img {
    display: flex;
    justify-content: center;
    width: 100%;
}

.img-in-modal {
    max-width: 700px;
    margin: auto;
    /* z-index: 20 !important; */
}