.services-section {
    margin-top: 75px;
}

.services-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* .services-heading {
    margin-bottom: 5px;
} */

/* .services-subheading {
    text-align: center;
    margin-top: 0px;
    padding: 0px 10px;
} */

.services-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.female-cuts,
.mens-cuts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-lists ul {
    background-color: white;
    width: 300px;
}

.list-heading,
.list-subheading {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.list-subheading {
    font-size: 1.2rem;
    padding: 0px 10px;
    /* font-family: 'Urbanist', sans-serif; */
}

.style-list {
    border: 2px solid black;
    margin: 20px 0px;
}

.style-list li {
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'Urbanist', sans-serif;
    /* border-top: 1px solid black; */
    padding: 10px;
    text-align: center;
}

.style-list li:not(:first-child) {
    border-top: 1px solid black;
}

.female-cuts {
    margin-bottom: 20px;
}