.testimonials-section {
    padding: 30px;
    padding-top: 0px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.home-header-container {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.home-header {
    font-size: 2.2rem;
    margin-bottom: 0px;
    margin-top: 30px;
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* .testimonials-header-container {
    flex-direction: column;
    align-items: center;
} */

/* .testimonials-header {
    margin-bottom: 10px;
} */

.leave-review-testimonials {
    margin-bottom: 20px;
    background-color: white;
}

.individual-testimonial {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px 20px;
    /* padding-top: 14px; */
    font-family: Karla, sans-serif;
    width: 200px;
    border: 2px solid black;
    transition: 0.7s all;
    text-decoration: none !important;
    height: 320px;
}

.individual-testimonial:hover {
    background-color: #222222;
    color: white;
}

.individual-testimonial a {
    transition: 0.7s all;
}

.individual-testimonial:hover a {
    color: white;
}

.individual-testimonial:hover .reviewer-image {
    border: 2px solid white;
}

.individual-testimonial:hover button {
    background-color: white;
    color: #222222;
}

.individual-testimonial:hover .my-review-link-button {
    border: 2px solid white;
}

.review-link {
    display: flex;
    justify-content: center;
    margin: auto;
    /* width: 100%; */
}

.my-review-link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    background-color: #222222;
    color: white;
    transition: 0.7s all;
    padding: 5px 10px;
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 1.2rem;
    height: 28px;
    /* width: 130px; */
}

.reviewer-name-container a {
    text-decoration: none;
}

.my-review-link-button:hover {
    background-color: white;
    color: #222222;
}

.first-three-testimonials {
    margin-bottom: 20px;
}

.reviewer-image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.reviewer-image {
    height: 60px;
    width: auto;
    border: 2px solid black;
    border-radius: 50%;
    transition: 0.7s all;
}

.review-details {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.reviewer-name {
    margin: 0px 0px;
    /* margin-top: -2px; */
    font-size: 1.4rem;
    font-weight: bold;
    font-family: 'Urbanist', sans-serif;
}

.review-text {
    margin: 10px 0px;
    height: 123px;
    overflow-y: auto;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: 'Urbanist', sans-serif;
}

.rating-and-image {
    display: flex;
    justify-content: center;
}

.reviewer-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-height: 320px;
}

.stars-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.star {
    margin: 1px;
    height: 27px;
}

.product-name-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 830px) {
    .first-three-testimonials {
        margin-bottom: 0px;
    }

    .testimonials-container {
        flex-direction: column;
        align-items: center;
    }

    .individual-testimonial {
        margin-bottom: 20px;
    }
}